import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const CreateWallet = () => {
  const [walletType, setWalletType] = useState('');
  const [walletTypes, setWalletTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWalletTypes = async () => {
      try {
        const token = localStorage.getItem('user_token');

        if (!token) {
          setError('No token found. Please log in again.');
          return;
        }

        const response = await axios.get(
          'https://organic-fertilizer.onrender.com/api/admin/wallet-types',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setWalletTypes(response.data.walletTypes);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          setError('Unauthorized: Your session has expired. Please log in again.');
          navigate('/login');
        } else {
          setError('Failed to fetch wallet types.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchWalletTypes();
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const token = localStorage.getItem('user_token');

    if (!walletType) {
      alert('Please enter a wallet type.');
      return;
    }

    try {
      const response = await axios.post(
        'https://organic-fertilizer.onrender.com/api/admin/create-wallet-type',
        { type: walletType },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setWalletTypes([...walletTypes, response.data.walletType]);
      setWalletType('');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError('Unauthorized: Your session has expired. Please log in again.');
        navigate('/login');
      } else if (error.response && error.response.data) {
        alert(error.response.data.error);
      }
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-danger">{error}</p>; // Bootstrap text-danger class

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          <h2>Create Wallet</h2>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit} className="mb-4">
            <div className="form-group">
              <input
                type="text"
                value={walletType}
                onChange={(e) => setWalletType(e.target.value)}
                placeholder="Enter wallet type (e.g., Savings)"
                className="form-control"
                style={{ maxWidth: '50%' ,
                margin: '0 auto', // Center the input
                display: 'block',
              marginBottom:'10px'}} // Make the input full width on mobile
                required
              />
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">Create</button>
            </div>
          </form>

          <h3 className="mb-4">Wallet Types</h3>
          <div className="table-responsive"> {/* Make the table scrollable on smaller screens */}
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {walletTypes.map((wallet) => (
                  <tr key={wallet._id}>
                    <td>{wallet._id}</td>
                    <td>{wallet.type}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateWallet;
