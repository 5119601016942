import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProductManagement = () => {
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    category: '',
    price: '',
    discount: '',
    quantity: '',
  });
  const [image, setImage] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);
  const bearerToken = localStorage.getItem('authToken');

  // Fetch products from the API
  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://organic-fertilizer.onrender.com/api/admin/products', {
        headers: { Authorization: `Bearer ${bearerToken}` }
      });
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  // Create a new product
  const createProduct = async () => {
    const formData = new FormData();
    formData.append('category', newProduct.category);
    formData.append('price', newProduct.price);
    formData.append('discount', newProduct.discount);
    formData.append('quantity', newProduct.quantity);
    formData.append('image', image);

    try {
      await axios.post('https://organic-fertilizer.onrender.com/api/admin/create-product', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${bearerToken}`
        }
      });
      fetchProducts();
      resetForm();
    } catch (error) {
      console.error('Error creating product:', error);
    }
  };

  // Edit an existing product
  const editProduct = async (productId) => {
    const formData = new FormData();
    formData.append('category', newProduct.category);
    formData.append('price', newProduct.price);
    formData.append('discount', newProduct.discount);
    formData.append('quantity', newProduct.quantity);
    if (image) formData.append('image', image);

    try {
      await axios.put(`https://organic-fertilizer.onrender.com/api/admin/products/${productId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${bearerToken}`
        }
      });
      fetchProducts();
      resetForm();
      setEditingProduct(null);
    } catch (error) {
      console.error('Error editing product:', error);
    }
  };

  // Delete a product
  const deleteProduct = async (productId) => {
    try {
      await axios.delete(`https://organic-fertilizer.onrender.com/api/admin/products/${productId}`, {
        headers: { Authorization: `Bearer ${bearerToken}` }
      });
      fetchProducts();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  // Reset form fields
  const resetForm = () => {
    setNewProduct({ category: '', price: '', discount: '', quantity: '' });
    setImage(null);
  };

  // Set the product for editing
  const handleEditClick = (product) => {
    setNewProduct({
      category: product.category,
      price: product.price,
      discount: product.discount,
      quantity: product.quantity
    });
    setEditingProduct(product._id);
  };

  // Submit the form for create or edit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingProduct) {
      editProduct(editingProduct);
    } else {
      createProduct();
    }
  };

  // Fetch products on component mount
  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="product-management-container">
      <h1 className="title">Product Management</h1>

      {/* Form for creating/editing a product */}
      <div className="form-container">
        <h2>{editingProduct ? 'Edit Product' : 'Create New Product'}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Category"
            value={newProduct.category}
            onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })}
            required
            className="input-field"
          />
          <input
            type="number"
            placeholder="Price"
            value={newProduct.price}
            onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
            required
            className="input-field"
          />
          <input
            type="number"
            placeholder="Discount (%)"
            value={newProduct.discount}
            onChange={(e) => setNewProduct({ ...newProduct, discount: e.target.value })}
            required
            className="input-field"
          />
          <input
            type="number"
            placeholder="Quantity"
            value={newProduct.quantity}
            onChange={(e) => setNewProduct({ ...newProduct, quantity: e.target.value })}
            required
            className="input-field"
          />
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            className="input-field"
          />
          <button type="submit" className="submit-button">
            {editingProduct ? 'Update Product' : 'Create Product'}
          </button>
        </form>
      </div>

      {/* Display the list of products */}
      <div className="products-list">
        <h2>Products List</h2>
        <ul>
          {products.map((product) => (
            <li key={product._id} className="product-card">
              <h3>{product.category}</h3>
              <p>Price: ₹{product.price}</p>
              <p>Discount: {product.discount}%</p>
              <p>Final Price: ₹{product.finalPrice}</p>
              <p>Quantity: {product.quantity}</p>
              {product.image && (
                <img
                  src={`https://organic-fertilizer.onrender.com/${product.image}`} // Update the image path if necessary
                  alt={product.category}
                  className="product-image"
                />
              )}
              <div className="product-actions">
                <button onClick={() => handleEditClick(product)} className="edit-button">Edit</button>
                <button onClick={() => deleteProduct(product._id)} className="delete-button">Delete</button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

// Styles
const styles = `
html, body {
  height: 100%;
  margin: 0;
}

.product-management-container {
 
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full height */
 
  padding: 20px;
  font-family: Arial, sans-serif;
}

.title {
  text-align: center;
  margin-bottom: 30px;

}

.form-container {
  margin-bottom: 20px;
  margin-right:1200px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f9f9f9;
}

.input-field {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.submit-button {
  padding: 10px 15px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.products-list {
  max-width: 800px;
 margin-top:30px;
}

.product-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.product-image {
  width: 100px;
  height: 100px;
}

.product-actions {
  margin-top: 10px;
}

.edit-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.delete-button {
  padding: 5px 10px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
@media (min-width: 1004px) {
  .product-management-container {
 margin-left:500px;}
}
/* Responsive Styles */
@media (max-width: 600px) {
  .product-management-container {
    padding: 10px;
  }

  .form-container {
    padding: 15px;
  }

  .input-field {
    padding: 8px;
  }

  .submit-button {
    padding: 8px 12px;
  }

  .product-card {
    padding: 15px;
  }

  .product-image {
    width: 80px;
    height: 80px;
  }
}

@media (min-width: 600px) {
  .product-management-container {
    padding: 20px;
  }
}
`;

// Append styles to the head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default ProductManagement;
