import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const BankDetails = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024); // Track screen size

  useEffect(() => {
    const fetchBankDetails = async () => {
      const token = localStorage.getItem("user_token"); // Retrieve the token from localStorage

      if (!token) {
        setError("No token found. Please login again.");
        return;
      }

      try {
        const response = await axios.get("https://organic-fertilizer.onrender.com/api/admin/users", {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        // Access the `users` array
        setData(response.data.users);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Session expired or unauthorized. Please log in again.");
          localStorage.removeItem("user_token"); // Clear the invalid token
          // Optionally redirect to login page
        } else {
          setError("Failed to fetch bank details. Please check your connection or try again later.");
        }
        console.error("Error fetching bank details:", error.response ? error.response.data : error.message);
      }
    };

    // Event listener to handle screen resizing
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024); // Update screen size on resize
    };

    window.addEventListener("resize", handleResize);

    fetchBankDetails();

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ marginLeft: isLargeScreen ? "18%" : "0%", transition: "margin-left 0.3s ease" }}> {/* Shift content based on screen size */}
      <Typography variant="h4" component="h2" gutterBottom>
        Bank Details
      </Typography>
      {error ? (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Bank Name</TableCell>
                <TableCell>Account Number</TableCell>
                <TableCell>IFSC Code</TableCell>
                <TableCell>Branch Name</TableCell>
                <TableCell>Passbook Image</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.flatMap(user => user.bankDetails ? [{
                userId: user.userId,
                name: user.name,
                ...user.bankDetails
              }] : []).map((details, index) => (
                <TableRow key={index}>
                  <TableCell>{details.userId}</TableCell>
                  <TableCell>{details.name}</TableCell>
                  <TableCell>{details.bankName}</TableCell>
                  <TableCell>{details.accountNumber}</TableCell>
                  <TableCell>{details.ifscCode}</TableCell>
                  <TableCell>{details.branchName}</TableCell>
                  <TableCell>
                    <a href={details.passbookImageUrl} target="_blank" rel="noopener noreferrer">
                      <Box
                        component="img"
                        src={details.passbookImageUrl}
                        alt="Passbook"
                        sx={{ width: 100, height: 'auto', borderRadius: 1, cursor: 'pointer' }} // Adjust styles as needed
                      />
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default BankDetails;
