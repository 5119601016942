import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const ReferralTypeManager = () => {
  const [referralTypes, setReferralTypes] = useState([]);
  const [typeName, setTypeName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch existing referral types
  useEffect(() => {
    const fetchReferralTypes = async () => {
      const token = localStorage.getItem('user_token');

      if (!token) {
        setError('No token found. Please log in again.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get('https://organic-fertilizer.onrender.com/api/admin/referral-types', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setReferralTypes(response.data.referralTypes);
      } catch (err) {
        console.error('Error Fetching Referral Types:', err.response);
        if (err.response && err.response.status === 401) {
          setError('Unauthorized: Your session may have expired. Please log in again.');
          localStorage.removeItem('user_token');
        } else {
          setError('Failed to fetch referral types.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchReferralTypes();
  }, []);

  // Handle form submission to create a new referral type
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccessMessage('');

    const token = localStorage.getItem('user_token');

    if (!token) {
      setError('No token found. Please log in again.');
      return;
    }

    if (!typeName || !description) {
      alert('Please fill in all fields.');
      return;
    }

    try {
      const response = await axios.post(
        'https://organic-fertilizer.onrender.com/api/admin/referral-type',
        { typeName, description },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Update success message and reset fields
      setTypeName('');
      setDescription('');
      setSuccessMessage('Referral type created successfully!');
      console.log('Response:', response.data);

      setReferralTypes([...referralTypes, response.data]);
    } catch (error) {
      console.error('Error Response:', error.response);
      if (error.response && error.response.status === 401) {
        setError('Unauthorized: Your session has expired. Please log in again.');
      } else {
        setError('Failed to create referral type.');
      }
    }
  };

  return (
    <div className="container mt-5">
      <div className="card shadow-sm">
        <div
          className="card-header text-white text-center py-3"
          style={{ backgroundColor: '#28a745' }} // Inline CSS for green background
        >
          <h2>Referral Type Manager</h2>
        </div>
        <div className="card-body p-4">
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {error && <div className="alert alert-danger">{error}</div>}

          <form onSubmit={handleSubmit} className="mb-4">
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Referral Type Name</label>
                <input
                  type="text"
                  value={typeName}
                  onChange={(e) => setTypeName(e.target.value)}
                  placeholder="Enter referral type name"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Description</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter description"
                  className="form-control"
                  rows="1"
                  style={{ resize: 'none' }} // Inline CSS to disable resizing
                  required
                />
              </div>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-success px-5">
                Create
              </button>
            </div>
          </form>

          {loading ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table table-hover table-bordered">
                <thead className="table-dark">
                  <tr>
                    <th>ID</th>
                    <th>Type Name</th>
                    <th>Description</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {referralTypes.map((referral) => (
                    <tr key={referral._id}>
                      <td>{referral._id}</td>
                      <td>{referral.typeName}</td>
                      <td>{referral.description}</td>
                      <td>{new Date(referral.createdAt).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferralTypeManager;
