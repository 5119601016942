import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const BankList = () => {
  const [banks, setBanks] = useState([]);
  const [newBankName, setNewBankName] = useState(""); // State for new bank name input
  const [error, setError] = useState("");

  useEffect(() => {
    fetchBanks();
  }, []);

  const fetchBanks = async () => {
    const token = localStorage.getItem("user_token");

    if (!token) {
      setError("No token found. Please log in again.");
      return;
    }

    try {
      const response = await axios.get(
        "https://organic-fertilizer.onrender.com/api/admin/banks",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setBanks(response.data.banks);
    } catch (error) {
      handleFetchError(error);
    }
  };

  const handleFetchError = (error) => {
    if (error.response && error.response.status === 401) {
      setError("Session expired or unauthorized. Please log in again.");
      localStorage.removeItem("user_token");
    } else {
      setError(
        "Failed to fetch bank data. Please check your connection or try again later."
      );
    }
    console.error(
      "Error fetching bank data:",
      error.response ? error.response.data : error.message
    );
  };

  const handleAddBank = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("user_token");

    if (!newBankName) {
      alert("Please enter a bank name.");
      return;
    }

    try {
      const response = await axios.post(
        "https://organic-fertilizer.onrender.com/api/admin/add-bank",
        { bankName: newBankName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setBanks([...banks, response.data]);
      setNewBankName(""); // Clear the input field
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Session expired or unauthorized. Please log in again.");
        localStorage.removeItem("user_token");
      } else {
        alert(
          error.response?.data?.message ||
            "Failed to add bank. Please try again."
        );
      }
    }
  };

  return (
    <Box
      sx={{
        marginLeft: {
          xs: 0, // No margin on small screens
          lg: "18%", // 18% margin on large screens
        },
      }}
    >
      <Typography variant="h4" component="h2" gutterBottom>
        Bank List
      </Typography>
      {error ? (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      ) : (
        <>
          <Box
            component="form"
            onSubmit={handleAddBank}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              mb: 3,
              width: "100%",
            }}
          >
            <TextField
              label="Bank Name"
              variant="outlined"
              value={newBankName}
              onChange={(e) => setNewBankName(e.target.value)}
              required
              sx={{ width: "100%" }} // Full width for input field
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                width: {
                  xs: "100%", // Full width on mobile
                  lg: "auto", // Auto width on large screens
                },
              }}
            >
              Add
            </Button>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Bank ID</TableCell>
                  <TableCell>Bank Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {banks.map((bank) => (
                  <TableRow key={bank._id}>
                    <TableCell>{bank._id}</TableCell>
                    <TableCell>{bank.bankName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default BankList;
