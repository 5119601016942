import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner, Alert, Pagination } from 'react-bootstrap'; // Optional Spinner and Alert components from Bootstrap

const PackageAmountManager = () => {
  const [packageAmounts, setPackageAmounts] = useState([]);
  const [amount, setAmount] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Fetch existing package amounts
  useEffect(() => {
    const fetchPackageAmounts = async () => {
      const token = localStorage.getItem('user_token');

      if (!token) {
        setErrorMessage('No token found. Please log in again.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get('https://organic-fertilizer.onrender.com/api/admin/package-amounts', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPackageAmounts(response.data.packageAmounts);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          setErrorMessage('Unauthorized: Your session may have expired. Please log in again.');
          localStorage.removeItem('user_token');
        } else {
          setErrorMessage('Failed to fetch package amounts.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPackageAmounts();
  }, []);

  // Handle form submission to create a new package amount
  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage('');
    setErrorMessage('');

    const token = localStorage.getItem('user_token');
    if (!token) {
      setErrorMessage('No token found. Please log in again.');
      return;
    }

    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      setErrorMessage('Please enter a valid positive amount.');
      return;
    }

    try {
      const response = await axios.post(
        'https://organic-fertilizer.onrender.com/api/admin/create-package-amount',
        { amount: parseInt(amount, 10) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.message) {
        setResponseMessage(response.data.message);
        setPackageAmounts([...packageAmounts, { _id: response.data._id, amount: parseInt(amount, 10) }]);
        setAmount(''); // Reset amount field
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Failed to create package amount. Please check your token or API.');
    }
  };

  // Pagination controls
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = packageAmounts.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div style={{ maxWidth: '900px', margin: '0 auto', marginTop: '50px', padding: '20px' }}>
      <div
        style={{
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          padding: '30px',
          borderRadius: '15px',
          backgroundColor: '#fff',
        }}
      >
        <h2 className="text-center" style={{ marginBottom: '30px', fontWeight: 'bold', color: '#333' }}>
          Package Amount Manager
        </h2>

        {/* Form for adding new package amounts */}
        <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
          <div style={{ marginBottom: '20px', textAlign: 'center' }}>
            <label
              htmlFor="amount"
              style={{
                fontWeight: 'bold',
                fontSize: '16px',
                color: '#495057',
                display: 'block',
                marginBottom: '10px',
              }}
            >
              Amount
            </label>
            <input
              type="number"
              style={{
                width: '50%',
                padding: '10px',
                borderRadius: '10px',
                border: '1px solid #28a745',
                textAlign: 'center',
                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
              }}
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
              placeholder="Enter amount"
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <button
              type="submit"
              style={{
                background: 'linear-gradient(45deg, #28a745, #28d78e)',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '10px',
                cursor: 'pointer',
                fontSize: '16px',
                fontWeight: 'bold',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                transition: 'background 0.3s ease',
              }}
              onMouseOver={(e) => (e.target.style.background = 'linear-gradient(45deg, #218838, #1fbf72)')}
              onMouseOut={(e) => (e.target.style.background = 'linear-gradient(45deg, #28a745, #28d78e)')}
            >
              {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
            </button>
          </div>
        </form>

        {/* Success and error messages */}
        {responseMessage && (
          <div style={{ textAlign: 'center', marginTop: '20px', color: '#28a745', fontWeight: 'bold' }}>
            {responseMessage}
          </div>
        )}
        {errorMessage && (
          <div style={{ textAlign: 'center', marginTop: '20px', color: '#dc3545', fontWeight: 'bold' }}>
            {errorMessage}
          </div>
        )}

        {/* Loading spinner while fetching package amounts */}
        {loading ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Spinner animation="border" />
            <p>Loading package amounts...</p>
          </div>
        ) : (
          <>
            {/* Table for displaying package amounts */}
            <table
              style={{
                width: '100%',
                marginTop: '20px',
                borderCollapse: 'collapse',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
                overflow: 'hidden',
              }}
            >
              <thead style={{ backgroundColor: '#28a745', color: 'white' }}>
                <tr>
                  <th style={{ padding: '15px', textAlign: 'center' }}>ID</th>
                  <th style={{ padding: '15px', textAlign: 'center' }}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((packageAmount) => (
                  <tr key={packageAmount._id} style={{ borderBottom: '1px solid #ddd' }}>
                    <td style={{ padding: '15px', textAlign: 'center' }}>{packageAmount._id}</td>
                    <td style={{ padding: '15px', textAlign: 'center' }}>{packageAmount.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination for package amounts */}
            <Pagination style={{ justifyContent: 'center', marginTop: '20px' }}>
              {Array.from({ length: Math.ceil(packageAmounts.length / itemsPerPage) }, (_, idx) => (
                <Pagination.Item
                  key={idx + 1}
                  active={idx + 1 === currentPage}
                  onClick={() => handlePageChange(idx + 1)}
                >
                  {idx + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </>
        )}
      </div>
    </div>
  );
};

export default PackageAmountManager;
