import React, { useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import MainDash from "./components/MainDash/MainDash";
import RightSide from "./components/RightSide/RightSide";
import Sidebar from "./components/Sidebar";
import LoginScreen from "./components/LoginScreen";

import AllUsersPage from "./components/AllUsersPage";

import HomePage from "./components/HomePage";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import UserProfile from './components/UserProfile';
import FundRequests from "./components/FundRequests";
import BankDetails from "./components/BankDetails";
import CreateWallet from "./components/CreateWallet";
import AddBank from "./components/AddBank";
import CreateReferralType from "./components/CreateReferralType"; 
import CreatePackage from "./components/CreatePackageAmount";
import CreateProduct from "./components/CreateProduct";
function App() {
  const [login, setLogin] = useState(!!localStorage.getItem("user_token"));
  const navigate = useNavigate();

  const handleLogin = () => {
    setLogin(true);
    navigate("/dashboard");
  };

  return (
    <Routes>
      <Route path="/login" element={<LoginScreen setLogin={handleLogin} />} />
      <Route
        path="/dashboard"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar
                  handleLogout={() => {
                    setLogin(false);
                    localStorage.removeItem("user_token");
                  }}
                />
                <HomePage />
                <RightSide />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
     
      <Route
        path="/create-product"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <CreateProduct />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="/user/:userId" element={<UserProfile />} />
      <Route
        path="/all-users"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <AllUsersPage />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
     
      <Route
        path="/fund-requests"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <FundRequests />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/bank-details" // Corrected route path
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <BankDetails />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      
      <Route
        path="/create-wallet"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <CreateWallet />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
          <Route
        path="/create-package"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <CreatePackage/>
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      
     
      <Route
        path="/add-bank"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                < AddBank/>
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/create referral"
        element={
          login ? (
            <div className="App">
              <div className="AppGlass">
                <Sidebar handleLogout={() => setLogin(false)} />
                <CreateReferralType />
              </div>
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="*" element={<Navigate to={login ? "/dashboard" : "/login"} />} />
    </Routes>
  );
}

export default App;
