import React, { useEffect, useState } from 'react';
import './Cards.css';
import { Link } from 'react-router-dom';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import axios from "axios";
import { API_URL } from '../utils';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import UnblockIcon from '@mui/icons-material/LockOpen';
import Stack from '@mui/material/Stack';

// EditableField Component
const EditableField = ({ label, value, onChange }) => {
  return (
    <div>
      <input
        type="text"
        placeholder={label}
        value={value}
        onChange={onChange}
        style={{ width: "100%" }}
      />
    </div>
  );
};

// Main Component
const AllUsersPage = () => {
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [userData, setUserData] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [phone, setPhone] = useState("");
  const [rerender, setRerender] = useState(true);
  const [editMode, setEditMode] = useState(null);
  const [editedData, setEditedData] = useState({});
  const apiBaseUrl = API_URL;

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("user_token");
      if (!token) {
        throw new Error("No authentication token found. Please log in.");
      }

      const response = await axios.get(`${apiBaseUrl}/api/admin/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Access users array from response data
      setUserData(response.data.users || []);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Session expired. Please log in again.");
        localStorage.removeItem("user_token");
        window.location.href = '/login';
      } else {
        console.error("Error fetching user data:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = async () => {
    try {
      const token = localStorage.getItem("user_token");
      if (!token) {
        alert("No authentication token found. Please log in.");
        return;
      }
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      let response;
      if (email) {
        response = await axios.post(`${apiBaseUrl}/super-admin/search-user-by-email`, { email }, config);
        setUserData([response.data]);
      } else if (phone) {
        // Updated to use the new API endpoint for phone search
        response = await axios.get(`https://organic-fertilizer.onrender.com/api/admin/search/phone/${phone}`, config);
        setUserData([response.data.user]);
      } else if (name) {
        // Updated to use the new API endpoint for name search
        response = await axios.get(`https://organic-fertilizer.onrender.com/api/admin/search/name/${name}`, config);
        setUserData([response.data.user]);
      } else if (userId) {
        // Updated to use the new API endpoint for userId search
        response = await axios.get(`https://organic-fertilizer.onrender.com/api/admin/search/userid/${userId}`, config);
        setUserData([response.data.user]);
      }
  
      setRerender(false);
      setTimeout(() => {
        setRerender(true);
      }, 1);
    } catch (error) {
      console.error("Error searching user:", error);
    }
  };
  

  const handleReset = () => {
    setStatus('');
    setStartDate('');
    setEndDate('');
    setEmail('');
    setName('');
    setUserId('');
    setPhone('');
    fetchData();
  };

  const handleEditClick = (row) => {
    setEditMode(row.userId);
    setEditedData({ ...row });
  };

  const handleSaveClick = async (userId) => {
    try {
      const token = localStorage.getItem("user_token");
      if (!token) {
        alert("No authentication token found. Please log in.");
        return;
      }

      await axios.put(
        `${apiBaseUrl}/super-admin/edit-user/${userId}`,
        {
          lastName: editedData.lastName,
          firstName: editedData.firstName,
          email: editedData.email,
          phone: editedData.phone,
          address: editedData.address,
          city: editedData.city,
          aadharNumber: editedData.aadharNumber,
          panNumber: editedData.panNumber,
          bankDetails: {
            accountNumber: editedData.bankDetails?.accountNumber,
            ifscCode: editedData.bankDetails?.ifscCode,
            bankName: editedData.bankDetails?.bankName,
            branchName: editedData.bankDetails?.branchName,
            holderName: editedData.bankDetails?.holderName,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert('User details updated successfully!');
      setEditMode(null);
      fetchData();
    } catch (error) {
      alert('Error updating user details.');
      console.error("Error updating user details:", error);
    }
  };

  const handleCancelClick = () => {
    setEditMode(null);
    setEditedData({});
  };

  const handleBlockUnblockUser = async (userId) => {
    try {
      const token = localStorage.getItem("user_token");
      if (!token) {
        alert("No authentication token found. Please log in.");
        return;
      }

      const user = userData.find(user => user.userId === userId);
      const endpoint = user.status === "blocked" ? "activate-user" : "block-user";
      await axios.put(`${API_URL}/super-admin/${endpoint}/${userId}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      fetchData();
    } catch (error) {
      console.error("Error blocking/unblocking user:", error);
    }
  };

  const handleDeleteUser = async (userId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (confirmDelete) {
      try {
        const token = localStorage.getItem("user_token");
        if (!token) {
          alert("No authentication token found. Please log in.");
          return;
        }

        await axios.delete(
          `${apiBaseUrl}/super-admin/delete-user/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert('User deleted successfully!');
        fetchData();
      } catch (error) {
        alert('Error deleting user.');
        console.error("Error deleting user:", error);
      }
    }
  };

  return (
    <div className="MainDash">
      <h1>All Users</h1>
      <div className="select-bar-container">
        <div className="select-bar">
          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="">Select Status</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="blocked">Banned</option>
          </select>

          <input
            type="text"
            placeholder="Search By Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />

          <input
            type="text"
            placeholder="Search By Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />

          <input
            type="text"
            placeholder="Search By User ID"
            value={userId}
            onChange={(e) => setUserId(e.currentTarget.value)}
          />

          <input
            type="text"
            placeholder="Search By Phone"
            value={phone}
            onChange={(e) => setPhone(e.currentTarget.value)}
          />

          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />

          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />

          <div className="button-group">
            <button onClick={handleSearch} className="search-button">Search</button>
            <button onClick={handleReset} className="reset-button">Reset</button>
        
          </div>
        </div>

        <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Aadhar</TableCell>
                <TableCell>Pan</TableCell>
                <TableCell>Bank</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(userData) && userData.length > 0 ? (
                userData.map((row) => (
                  <TableRow key={row.userId}>
                    <TableCell>{row.userId}</TableCell>
                    <TableCell>
                      {editMode === row.userId ? (
                        <EditableField
                          label="First Name"
                          value={editedData.firstName || ''}
                          onChange={(e) => setEditedData({ ...editedData, firstName: e.target.value })}
                        />
                      ) : (
                        row.name
                      )}
                    </TableCell>
                    <TableCell>
                      {editMode === row.userId ? (
                        <EditableField
                          label="Email"
                          value={editedData.email || ''}
                          onChange={(e) => setEditedData({ ...editedData, email: e.target.value })}
                        />
                      ) : (
                        row.email
                      )}
                    </TableCell>
                    <TableCell>
                      {editMode === row.userId ? (
                        <EditableField
                          label="Phone"
                          value={editedData.phone || ''}
                          onChange={(e) => setEditedData({ ...editedData, phone: e.target.value })}
                        />
                      ) : (
                        row.mobile
                      )}
                    </TableCell>
                    <TableCell>
                      {editMode === row.userId ? (
                        <EditableField
                          label="Address"
                          value={editedData.address || ''}
                          onChange={(e) => setEditedData({ ...editedData, address: e.target.value })}
                        />
                      ) : (
                        row.address
                      )}
                    </TableCell>
                    <TableCell>
                      {editMode === row.userId ? (
                        <EditableField
                          label="City"
                          value={editedData.city || ''}
                          onChange={(e) => setEditedData({ ...editedData, city: e.target.value })}
                        />
                      ) : (
                        row.city
                      )}
                    </TableCell>
                    <TableCell>
                      {editMode === row.userId ? (
                        <EditableField
                          label="Aadhar Number"
                          value={editedData.aadharNumber || ''}
                          onChange={(e) => setEditedData({ ...editedData, aadharNumber: e.target.value })}
                        />
                      ) : (
                        row.aadharNumber
                      )}
                    </TableCell>
                    <TableCell>
                      {editMode === row.userId ? (
                        <EditableField
                          label="Pan Number"
                          value={editedData.panNumber || ''}
                          onChange={(e) => setEditedData({ ...editedData, panNumber: e.target.value })}
                        />
                      ) : (
                        row.panNumber
                      )}
                    </TableCell>
                    <TableCell>
                      {editMode === row.userId ? (
                        <EditableField
                          label="Bank Account Number"
                          value={editedData.bankDetails?.accountNumber || ''}
                          onChange={(e) => setEditedData({ ...editedData, bankDetails: { ...editedData.bankDetails, accountNumber: e.target.value } })}
                        />
                      ) : (
                        row.bankDetails?.accountNumber
                      )}
                      <br />
                     
                      <br />
                      {editMode === row.userId ? (
                        <EditableField
                          label="Bank Name"
                          value={editedData.bankDetails?.bankName || ''}
                          onChange={(e) => setEditedData({ ...editedData, bankDetails: { ...editedData.bankDetails, bankName: e.target.value } })}
                        />
                      ) : (
                        row.bankDetails?.bankName
                      )}
                      <br />
                      {editMode === row.userId ? (
                        <EditableField
                          label="Branch Name"
                          value={editedData.bankDetails?.branchName || ''}
                          onChange={(e) => setEditedData({ ...editedData, bankDetails: { ...editedData.bankDetails, branchName: e.target.value } })}
                        />
                      ) : (
                        row.bankDetails?.branchName
                      )}
                      <br />
                      {editMode === row.userId ? (
                        <EditableField
                          label="Account Holder Name"
                          value={editedData.bankDetails?.name || ''}
                          onChange={(e) => setEditedData({ ...editedData, bankDetails: { ...editedData.bankDetails, holderName: e.target.value } })}
                        />
                      ) : (
                        row.bankDetails?.holderName
                      )}
                    </TableCell>
                    <TableCell>
                      {editMode === row.userId ? (
                        <Stack direction="row" spacing={1}>
                          <Button onClick={() => handleSaveClick(row.userId)} color="success" variant="contained" startIcon={<SaveIcon />}>Save</Button>
                          <Button onClick={handleCancelClick} color="error" variant="contained" startIcon={<CancelIcon />}>Cancel</Button>
                        </Stack>
                      ) : (
                        <Stack direction="row" spacing={1}>
                          <Button onClick={() => handleEditClick(row)} color="info" variant="contained" startIcon={<EditIcon />}>Edit</Button>
                          <Button onClick={() => handleBlockUnblockUser(row.userId)} color={row.status === 'blocked' ? 'success' : 'error'} variant="contained" startIcon={row.status === 'blocked' ? <UnblockIcon /> : <BlockIcon />}>
                            {row.status === 'blocked' ? 'Unblock' : 'Block'}
                          </Button>
                          <Button onClick={() => handleDeleteUser(row.userId)} color="error" variant="contained" startIcon={<DeleteIcon />}>Delete</Button>
                        </Stack>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10} align="center">No users found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AllUsersPage;
