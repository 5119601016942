import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import Logo from "../imgs/logo.png";
import { UilSignOutAlt, UilHome, UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";

const Sidebar = ({ handleLogout }) => {
  const Catalog = [
    {
      name: "User Management",
      children: [
        "All Users", 
       
       
                 // New route
       
        
      
      ],
    }, {
      name: "Fund and Wallet",
    children:["Fund Requests","Create Wallet"],},
    {
      name: "Referral Management",
      children: ["Create Referral"],
    },
    {
      name: "Activate/Upgrade",
      children: ["Create Package","Create Product"],
    },
    {
      name: "Bank Manager",
      children: ["Bank Details" , "Add Bank"  ],
    },
 
    
    
  ];

  const [selected, setSelected] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const navigate = useNavigate();

  const sidebarVariants = {
    expanded: {
      left: '0',
    },
    collapsed: {
      left: '-100%',
    },
  };

  return (
    <><div className="bars" onClick={() => setExpanded(!expanded)}>
    <UilBars />
  </div>
      <motion.div
        className="sidebar"
        variants={sidebarVariants}
        animate={expanded ? "expanded" : "collapsed"}
      >
        <div className="logo">
          <img src={Logo} alt="logo" />
          <span>
           Ja<span>bs</span> Organic
          </span>
        </div>

        <div className="menu">
          <div className="menuItem" onClick={() => navigate("/dashboard")}>
            <UilHome /> <span>Home</span>
          </div>
          {Catalog.map((item, index) => (
            <div
              className={selected === index ? "menuItem active" : "menuItem"}
              key={index}
              onClick={() => setSelected(selected === index ? null : index)}
            >
              <span>{item.name}</span>
              {selected === index && (
                <ul>
                  {item.children.map((child, childIndex) => (
                    <li key={childIndex}>
                      <Link
                        to={
                           child === "All Users"
                            ? "/all-users"
                            :child === "Create Package"
                            ? "/create-package"
                            :child === "Create Product"
                            ? "/create-product"
                            :child === "Create Wallet"
                            ? "/create-wallet"
                            :child === "Add Bank"
                            ? "/add-bank"
                            :child === "Create Referral"
                            ? "/create referral"
                            : child === "Fund Requests"
                            ? "/fund-requests"              // New Route
                            : child === "Bank Details"
                            ? "/bank-details"  
                         
                            : ""
                        }
                      >
                        {child}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          <div className="menuItem" onClick={handleLogout}>
            <UilSignOutAlt />
            <span>Logout</span>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
