import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Dialog from "@mui/material/Dialog";
import { API_URL } from "../utils";

const FundRequests = () => {
  const [fundRequests, setFundRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    const fetchFundRequests = async () => {
      const token = localStorage.getItem("user_token");

      if (!token) {
        setError("No token found. Please login again.");
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/api/admin/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const allFundRequests = response.data.users.flatMap((user) => user.fundRequests);
        setFundRequests(allFundRequests);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Session expired or unauthorized. Please log in again.");
          localStorage.removeItem("user_token");
        } else {
          setError("Failed to fetch fund requests. Please check your connection or try again later.");
        }
        setLoading(false);
      }
    };

    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);

    fetchFundRequests();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredFundRequests = fundRequests.filter((request) =>
    request.bankName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "success";
      case "pending":
        return "warning";
      case "rejected":
        return "error";
      default:
        return "default";
    }
  };

  const handlePreviewImage = (imageUrl) => {
    setPreviewImage(imageUrl);
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  return (
    <Container>
      <Box sx={{ marginLeft: isLargeScreen ? "28%" : "0%", transition: "margin-left 0.3s ease", marginBottom: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Fund Requests
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
          <InputBase
            sx={{ ml: 1, flex: 1, backgroundColor: "#f1f1f1", padding: "5px 10px", borderRadius: "4px" }}
            placeholder="Search by Bank Name"
            inputProps={{ "aria-label": "search" }}
            value={searchQuery}
            onChange={handleSearch}
          />
          <IconButton sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Box>

        {error ? (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        ) : loading ? (
          <Skeleton variant="rectangular" width="100%" height={200} />
        ) : (
          <Paper elevation={3}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell>Bank Name</TableCell>
                    <TableCell>Account Number</TableCell>
                    <TableCell>IFSC Code</TableCell>
                    <TableCell>Request Amount</TableCell>
                    <TableCell>UTR Number</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Slip Image</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredFundRequests
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((request, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:hover": { backgroundColor: "#f1f1f1", cursor: "pointer" },
                        }}
                      >
                        <TableCell>{request.bankName}</TableCell>
                        <TableCell>{request.accountNumber}</TableCell>
                        <TableCell>{request.ifscCode}</TableCell>
                        <TableCell>{request.requestAmount}</TableCell>
                        <TableCell>{request.utrNumber}</TableCell>
                        <TableCell>
                          <Chip label={request.status} color={getStatusColor(request.status)} />
                        </TableCell>
                        <TableCell>
                          <a onClick={() => handlePreviewImage(request.slipImageUrl)} style={{ cursor: "pointer" }}>
                            View Slip
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={filteredFundRequests.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ backgroundColor: "#f5f5f5" }}
            />
          </Paper>
        )}
      </Box>

      <Dialog open={!!previewImage} onClose={handleClosePreview} maxWidth="md" fullWidth>
        <img src={previewImage} alt="Slip Preview" style={{ width: "100%" }} />
      </Dialog>
    </Container>
  );
};

export default FundRequests;
